import React from "react";
import { Link } from "gatsby";
import ButtonLink from "../components/button";
import '../styles/components/navbar.scss';
import logo from "../images/logo.png"
import { motion } from 'framer-motion';

const delayed = (delay, xOffset) => {
  return {
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 0.5,
        delay
      }
    },
    hidden: {
      opacity: 0,
      x: xOffset
    }
  }
}

function Navbar() {
  return (
    <div className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <motion.div
            variants={delayed(0.2, -200)}
            initial="hidden"
            animate="visible"
          >
            {/* <img src={logo} alt="West Coast Metal Roofing" width="50" height="50" class="d-inline-block align-text-top" /> */}
            West Coast Metal Roofing
          </motion.div>
        </Link>
        <motion.button
          variants={delayed(0.2, 200)}
          initial="hidden"
          animate="visible"
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </motion.button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <motion.li
              variants={delayed(0.2, 200)}
              initial="hidden"
              animate="visible"
            >
              <ButtonLink to="/contact" bg={"#FFB800"}>
                Get in touch
              </ButtonLink>
            </motion.li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export function NavbarDark() {
  return (
    <div className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        <Link className="navbar-brand" to="/">
          West Coast Metal Roofing
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li>
              <ButtonLink to="#" bg={"#FFB800"}>
                Get in touch
              </ButtonLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
