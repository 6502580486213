import React from "react";
import Helmet from "react-helmet";
import favicon from "../images/logo.png";

function Head() {
  return (
    <Helmet>
      <meta name="viewport" content="width=device-width; initial-scale=1.0;" />
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x"
        crossorigin="anonymous"
      />
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-gtEjrD/SeCtmISkJkNUaaKMoLD0//ElJ19smozuHV6z3Iehds+3Ulb9Bn9Plx0x4"
        crossorigin="anonymous"
      />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap"
        rel="stylesheet"
      />
      <link rel="icon" href={favicon} type="image/x-icon" />
      <meta
        name="description"
        content="West Coast Metal Roofing is the premier roofing company, serving
        counties including Los Angeles, San Bernardino, San Diego, and anywhere
        else you’ll need us.
        "
      />
      <title>West Coast Metal Roofing</title>
    </Helmet>
  );
}

export default Head;
