import React from 'react'
import Button from '@material-ui/core/Button';
import { Link } from 'gatsby';
import "../styles/components/buttons.scss"

function ButtonLink({to, bg, children}) {
  return (
    <Button className="button-link" component={Link} to={to} style={{backgroundColor: bg}}>
      {children}
    </Button>
  )
}

export default ButtonLink;