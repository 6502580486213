import React from 'react';
import Head from '../components/head'
import Navbar, { NavbarDark } from '../components/navbar';

function PageWrapper({children}) {  
  return (
    <>
      <Head />
      <Navbar/>
      {children}
    </>
  );
}

export function PageWrapperDark({children}) {  
  return (
    <>
      <Head />
      <NavbarDark/>
      {children}
    </>
  );
}

export default PageWrapper;